import { LoginService } from './login.service';
import { StopsAggregation } from '../models/stop-aggregation';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Response, ResponseContentType, Headers, RequestOptions } from '@angular/http';
import { Stop } from 'app/models/stop';

import 'rxjs/add/operator/toPromise';
import { EnvConfigurationService } from './env-config.service';

var moment = require('moment-timezone');

@Injectable()
export class StopService {
    constructor(
        private http: HttpService,
        private _loginService: LoginService,
        private envSettings: EnvConfigurationService
    ) { }

    /**
     * Get A download report link for the XLS
     *
     * @returns {string}
     *
     * @memberOf StopService
     */
    getXLSLink(deviceId: number, dateFrom: Date, dateTo: Date, productId?: number): string {
        let url = `${this.envSettings.settings.excelExportBase}/v1/stopHistory/xlsx?access_token=${this._loginService.token}&dateFrom=${dateFrom?dateFrom.toISOString(): null}&dateTo=${dateTo? dateTo.toISOString(): null}&deviceId=${deviceId}&timezone=${moment.tz.guess()}`;
        if (productId) {
            url += `&productId=${productId}`;
        }

        return url;
    }

    getReport(deviceId: number, dateFrom: Date, dateTo: Date, productId?: number): Promise<Response> {
        const url = this.getXLSLink(deviceId, dateFrom, dateTo, productId);

        let headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        let options = new RequestOptions();
        options.responseType = ResponseContentType.Blob;
        options.headers = headers;

        return this.http.post(url, null, options).toPromise();
    }

    getAll(startDate: string, endDate: string, deviceId: number, productId: number, schedules?: string, includeSplits?: boolean, orderCoreId?: number): Promise<Stop[]> {
        let url;
        if (!startDate || !endDate) {
            url = `${this.envSettings.settings.apiBase}/stopHistory`;
        } else {
            url = `${this.envSettings.settings.apiBase}/stopHistory?fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;
        }
        if (productId) {
            url += `&productId=${productId}`;
        }
        if (schedules && schedules.length) {
            url +=  `&shifts=${schedules}`
        }
        if (typeof includeSplits != undefined) {
            url += `&includeSplits=${includeSplits}`;
        }
        if (orderCoreId) {
            url += `&orderId=${orderCoreId}`
        }

        return this.http
            .get(url)
            .toPromise()
            .then(response => response.json() as Stop[]);
    }
    getAggregate(startDate: string, endDate: string, deviceId: number, productId: number, schedules?: string): Promise<StopsAggregation[]> {
        let url = `${this.envSettings.settings.apiBase}/stopHistory/aggregates?fillZero=false`;

        if (startDate && endDate) {
            url += `&fromDate=${startDate}&toDate=${endDate}&deviceId=${deviceId}`;
        }
        if (productId) {
            url += `&productId=${productId}`;
        }
        if (schedules && schedules.length) {
            url +=  `&shifts=${schedules}`
        }

        return this.http
            .get(url)
            .toPromise()
            .then(response => response.json() as StopsAggregation[]);
    }

    /**
     * Set an error to a past stop
     *
     * @param {number} stopId
     * @param {number} errorId
     *
     * @memberOf StopService
     */
    async setError(stopId: number, errorId: number) {
        const url = `${this.envSettings.settings.apiBase}/stopHistory/${stopId}/error`;
        await this.http
            .put(url, {
                errorId: errorId
            })
            .toPromise();
    }

    /**
     * Set the type of a past stop
     *
     * @param {number} stopId
     * @param {number} errorId
     *
     * @memberOf StopService
     */
    async setType(stopId: number, stopType: number) {
        const url = `${this.envSettings.settings.apiBase}/stopHistory/${stopId}/changeOver`;
        await this.http
            .put(url, {
                stopType
            })
            .toPromise();
    }

    /**
     * Set a note to a past stop
     *
     * @param {number} stopId
     * @param {string} note
     *
     * @memberOf StopService
     */
    async setNote(stopId: number, note: string) {
        const url = `${this.envSettings.settings.apiBase}/stopHistory/${stopId}`;
        await this.http
            .put(url, {
                note: note
            })
            .toPromise();
    }


    async updateStop(stop: Stop) {
        if (!stop || !stop.id) return null;

        const url = `${this.envSettings.settings.apiBase}/stopHistory/stop/${stop.id}`;
        let mappedStop = {
            note: stop.note
        };

        if (stop.errorId != undefined && stop.errorId != null) {
            mappedStop['errorId'] = stop.errorId;
        } else {
            mappedStop['stopType'] = stop.type;
        }

        return await this.http
                .put(url, mappedStop)
                .toPromise();
    }
    async updateSplits(stopId: number, splits: Stop[]) {
        const url = `${this.envSettings.settings.apiBase}/stopHistory/split/${stopId}`;

        let mappedSplits = splits.map(el => {
            return {
                type: el.type,
                errorId: el.errorId,
                beginAt: el.beginAt,
                endAt: el.endAt,
                deviceId: el.deviceId,
                productId: el.productId,
                note: el.note
            }
        });
        return await this.http
                .put(url, {
                    splits: mappedSplits
                })
                .toPromise();
    }
}
